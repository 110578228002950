<script setup>
import { useDisplay } from "vuetify";
const display = useDisplay();

const props = defineProps({
  blok: Object,
  sectionBlock: Object,
});
const { useCases, link } = await useUseCases();
const config = await useConfig();
const { parse } = useUtils();
const currentSlide = ref(0);
const selection = ref(useCases.value?.[0]);
</script>

<template>
  <div v-editable="blok" class="feature-bento-box pb-0 relative">
    <div
      class="hidden md:flex md:visible justify-end relative"
      style="z-index: 10"
    >
      <div class="cta-tabs-menu w-tab-menu" role="tablist">
        <button
          v-for="(useCase, i) in useCases"
          :key="useCase.slug"
          class="cta-tab-link w-inline-block w-tab-link"
          :class="{ 'w--current': currentSlide === i }"
          href="#"
          role="tab"
          aria-controls="w-tabs-0-data-w-pane-0"
          :aria-selected="currentSlide === i ? 'true' : 'false'"
          @click="(currentSlide = i), (selection = useCase)"
        >
          <div>{{ useCase.name }}</div>
        </button>
      </div>
    </div>
    <div class="md:hidden">
      <v-select
        v-model="selection"
        :items="useCases"
        item-title="name"
        return-object
        hide-details
        dense
        outlined
        :label="$t('challenge.select.label')"
        class="w-full"
        @update:model-value="currentSlide = useCases.indexOf($event)"
      />
    </div>

    <div
      v-for="(useCase, i) in useCases"
      v-show="currentSlide === i"
      :key="useCase.slug"
      class="cta-wrapper"
      :class="[useCase.slug]"
    >
      <div class="cta-content-holder">
        <strong>{{ parse(useCase.content.teaser_headline) }}</strong>
        <h2>
          {{ useCase.content.pre_headline }}
        </h2>
        <div class="text-wrap">
          <p>{{ useCase.content.description }}</p>
        </div>
        <div class="cta-buttons mt-8 mb-10 d-block d-sm-flex">
          <MyLink button v-bind="link(useCase)" />
          <MyLink
            :link="{ url: '@showSchedulePopup', linktype: 'url' }"
            :link-text="config.contact_cta[0].button_text"
            :button="false"
            class="mt-4 mt-sm-0"
          >
          </MyLink>
        </div>
      </div>
      <div
        class="cta-tabs-wrap"
        :class="{ 'text-center': !display.mdAndUp.value }"
      >
        <NuxtImg
          v-if="useCase.content.image"
          :height="display.mdAndUp.value ? 500 : 200"
          :src="
            useCase.content.image.filename || '../../images/Old_Man_King.png'
          "
          :alt="useCase.content.image.alt"
          loading="lazy"
          class="image home-challenge-slider-image pt-4"
        />
      </div>
    </div>
    <BgGradient position="right" :width="!display.mobile.value ? 90 : 100" />
  </div>
</template>

<style scoped lang="scss">
.cta-tabs-wrap {
  width: 50%;
  height: 100%;
  padding-top: 3em;
  padding-left: 3em;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
  overflow: hidden;
}

@media screen and (min-width: 992px) {
  .image.home-challenge-slider-image {
    margin: 0 auto;
    object-fit: cover;
    object-position: left bottom;
  }
  .cta-wrapper.change-management {
    .image.home-challenge-slider-image {
      object-fit: none;
    }
  }
}

.border-bottom {
  border-bottom: 1px solid #ebebeb;
}
.text-wrap {
  max-width: 550px;
}

@media screen and (max-width: 599px) {
  .cta-tabs-wrap {
    padding-left: 1em;
  }
}

@media screen and (max-width: 991px) {
  .cta-tabs-wrap {
    width: 100%;
    padding-top: 0;
    position: relative;

    img {
      padding: 0 !important;
    }
  }
}
</style>
